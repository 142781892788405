@media screen and (max-width: 750px){
  .mainbanner{
    padding: 0 24px;
    padding-bottom: 40px;
  }
  .container .img-mainbanner img{
    display: none;
  }
  .container h2{
    font-size: 32px;
  }
  .container h4{
    font-size: 24px;
    line-height: 32px;
  }
}