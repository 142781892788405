.svgDesign {
  background-color: rgba(241, 243, 246, 1);
}

.subs-container {
  background-color: rgba(241, 243, 246, 1);
  min-height: 100vh;
}

.boxHeading {
  margin-bottom: 24px;
}

.subBox {
  width: 912px;
  height: 568px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25);
  padding: 48px 0;
}

.main-heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

#main-heading {
  color: rgba(0, 133, 129, 1);
}

.mainSection {
  padding: 0 48px;
}

.elemHeading h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.elem input,
.elem select {
  border: none;
  border-bottom: 1px solid rgba(152, 155, 176, 1) !important;
  width: 100%;
  padding: 4px 6px;
}

.elem Input:hover,
.elem Select:hover {
  border-bottom: 2px solid #009999 !important;
}

.elem Input:focus-visible,
.elem select:focus-visible {
  outline: none;
}

#country {
  padding-bottom: 0.5px;
}

.element {
  margin-bottom: 24px;
}

.exp {
  margin-left: 24px;
  margin-right: 12px;
}

.cvccvv {
  margin-left: 12px;
}

.zipCode {
  padding-left: 0 !important;
}

.cname {
  padding-right: 0 !important;
}

#payButton {
  width: 165px;
  border: 2px !important;
  height: 48px;
  border-radius: 100px;
  background-color: rgba(0, 153, 153, 1);
  color: white;
  padding: 12px 24px 12px 24px;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  margin-left: 40%;
  margin-top: 8px;
}

.stripeInput {
  padding: 4px 6px !important;
}

.stripeInput:hover {
  border-bottom: 2px solid #009999 !important;
}

@media only screen and (max-width: 767px) {
  #section2 {
    display: flex !important;
    flex-direction: column !important;
  }

  #section2a {
    width: 100% !important;
  }

  #section2b {
    display: flex;
    flex-direction: row;
    width: 100% !important;
  }

  #section2b .exp {
    margin-left: 0;
  }

  .section2bi {
    width: 70%;
  }

  .section2bii {
    width: 30%;
  }
}
