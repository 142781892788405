.nav-container {
    min-height: 96px !important; 
    background-color: #009999 !important;
    display: flex !important; 
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    padding: 0 20px;
    position: relative;
  }
  
  .nav-text {
    color: white !important;
    padding: 0 20px !important;
    font-size: 24px !important;
    padding-top: 14px !important;
    font-weight: 600 !important;
  }
  
  .nav-btn {
    padding: 0 20px !important;
  }
  .nav-btn svg {
    width: 179px !important;
    height: 160px !important;
    position: absolute !important;
    top: -30% !important;
    transform: rotate(-2deg);
    right: 15.5% !important;
  }
  
  .btn-navbar {
    font-size: 15px !important;
    border: none !important;
    font-weight: 500 !important;
    background-color: #fff !important;
    width: 164px !important;
    height: 40px !important;  
    border-radius: 200px !important;
    cursor: pointer !important;
    transition: all 0.2s ease-in-out !important;
  }
  
  .btn-navbar:hover {
   background-color: #1AACA9 !important;
   color: white !important;
  }