.heading {
    color:#3A4468;
    font-weight: 600;
}
.mainWrapper {
    padding: 80px 0;
    background-color: #F1F3F6;
    margin: 100px 0;
}
.button {
    background-color: #009999;
    color: white;
    border: none;
    width: 270px;
    height: 45px;
    font-size: 20px;
    margin: 20px auto;
    font-weight: bold;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    -ms-border-radius: 500px;
    -o-border-radius: 500px;
}
a,a:hover {
    color: #009999;
}
.setPosition {
    position: sticky;
    top: 0;
    padding: 50px 0;
}
@media only screen and (max-width:768px) {
    .setPosition {
        display:none !important;
    }
}