.price-main-container {
    min-height: 190vh;
    background-color: #f5f8fd;
}

.price-main-container svg {
    margin-top: 20px !important;
}

.pricing-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
}
.pricing-head h1{
    font-weight: 600;
    font-size: 24px;
    color: #061E46;
}


.price-body {
    width: 912px;
    height: 976px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.price-body h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: black;
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: 0.23px;
}

.tab {
    width: 805px;
    height: 40px !important;   
    cursor: pointer;
    margin: 10px 0px;
}

.w-tab {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    border: 1px solid #C9CFDB;
    transition: all 0.3s ease;
}

.w-tab p {
    margin-bottom: 0;
    text-align: flex-start;
}

.w-tab:hover {
    border: 1px solid #008581 !important;
}

.tab0 {
    background: #686E8B;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    cursor: default;
}

.tab0 p {
    font-weight: 600;
    font-size: 16px; 
    color: white;
    line-height:24px;
    margin-bottom: 0;
}

#tab0Head {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: white !important;
}

.calculator-container {
   width: 816px;
   height: 344px;
   background: #EAF6F5;
   border-radius: 10px;
   margin-top: 30px;
   padding: 8px 24px;
   padding-bottom: 24px;
}

.cal-head h1 {
    font-weight: 600;
    font-size: 32px;
    color: #008581;
    margin: 0;
    line-height: 40px;
    margin-bottom: 8px !important;
    margin-top: 24px !important;
}
.calculator-box {
    width: 758px;
    height: 248px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px 1rem;
}

.price-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-range h2 {
    margin: 0 !important;
    margin-top: 20px !important;
    font-weight: 400 !important;
    font-size: 24px;
    color: #0E0E2C;
}
.price-range-box {
    margin-top: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arth-btn {
    width: 48px;
    height: 48px;
    color: #0E0E2C;
    font-size: 24px;
    background-color: #fff;
    border: 1px solid #989BB0;
    transition: all 0.3s ease;
    cursor: pointer;
}
.arth-btn:hover {
    background: #EAF6F5;
    color: #008581;
}
.plus {
    border-radius: 10px 0px 0px 10px;
}
.minus {
    border-radius: 0px 10px 10px 0px;
}
.price-input {
    width: 140px;
    height: 48px;
    border: 1px solid #989BB0;
    border-left: none !important;
    border-right: none !important;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}

.price-display {
    text-align: center;
}

.price-display h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    margin: 0 !important;
    margin-top: 48px !important;
    margin-bottom: 24px !important;
}
.price-display span {
    font-size: 32px;
    font-weight: 600;
}
.price-display h3 {
    font-weight: 400;
    font-size: 24px;
    color: #0E0E2C;
}

#priceMonth {
    color: black;
    font-size: 32px;
    line-height:32px;
    font-weight: 600;
}

.continue-btn-cont {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
}
.continue-btn {
  vertical-align: middle;
  background: #009999;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border: none;
  width: 141px;
  height: 48px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.continue-btn:hover {
    background: #1AACA9;
}

.continue-btn-cont svg{
 margin: 0 !important;
 vertical-align: middle;
}