.otp_container {
  min-width: 100vw;
  min-height: 100vh;
  background: #eaedf2;
  margin: 0px !important;
}

.otp_row_1 {
  border-radius: 20px;
  background: #ffffff;
  min-width: 623px;
  height: 436px;
  padding-top: 16px;
  padding-bottom: 48px;
  margin: 0px !important;
}

.otp_row_1 h2 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #061e46;
}

.otp_row_1 p {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0e0e2c;
}

.otp_row_1 .col {
  padding-top: 0px !important;
}

.otp_row_1 .btn_collection .btn {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 100px;
  padding: 12px 24px;
}

.otp_row_1 .btn_collection .btn:first-child {
  background: #CC4736;
  border: none;
  outline: none !important;
}

.otp_row_1 .btn_collection .btn:nth-child(2) {
  border: none;
  outline: none !important;
  background: #009999;
}

.otp_row_1 p:last-child .btn {
  border: none;
  color: #3a4468;
  background: #ffffff !important;
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  outline: none !important;
}

.otp-field {
  margin-right: 10px !important;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: outset !important;
  border-color: #bbcadc !important;
  border-width: 2px !important;
  width: 56px !important;
  height: 56px !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}

.otp-field:focus {
  outline: none;
  background: #f1f3f6;
  box-shadow: inset 0px -1px 0px #a1b6cf;
}

.text-center > p:last-child {
  margin-top: 20px;
}

.text-center > p:last-child > .btn:first-child {
  margin-right: 10px;
}

.text-center {
  padding-top: 10% !important;
}

.dsgn {
  display:flex;
  flex-direction:column;
}


.btn_collection {
  margin: 56px 0 32px 0 !important;
}

@media only screen and (max-width:767px) {
  .otp_container {
    padding: 0px !important;
  }

  .otp_row_1{
    border-radius: 0;
    width: 100%;
    height: 100vh;
  }

  .wel_head {
    margin-top: 2rem;
    margin-bottom: 12px;
  }

  #codePara {
    margin-bottom: 12px;
  }

  .btn_collection {
    display: flex;
    flex-direction:column-reverse;
    justify-content:center;
    align-items: center !important;
  }

  .btn_collection button {
    margin: 1rem !important;
    height: 48px;
    width: 182px;
  }

  .clearBtn {
    margin: 0 !important;
  }
}