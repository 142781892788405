.popup1 {
  position:fixed !important;
  top: 0 !important;
  left: 0 !important;
  min-width: 100%;
  min-height: 100%;
  background-color: #0e0e2c87;
  z-index: 5 !important;
  overflow: hidden !important;
}

.popup-inner1 {
  position:absolute;
  transform: translate(50%,-150%);
  width: 448px;
  height: 312px;
  padding: 0px 0px 48px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25);
  z-index: 10 !important;
}

.popup-header1 {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.popup-inner1 .close-btn1 {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-weight: 800;
  font-size: 15px;
  cursor: pointer;
  margin-right: 2px;
}

.popup-cont1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 24px !important;
  width: 100%;
  margin: 3% 0 !important;
}

.popup-btn1 {
  width: 148px !important;
  height: 4px;
  text-align: center;
  position: absolute;
  bottom: 25% !important;
  left: 48%;
  transform: translate(-48%, -25%) !important;
}

.update-btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 158px;
  height: 40px;
  background: #009999;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: white;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 1.5px;
  transition: all 0.2s ease-in-out;
}

.update-btn1:hover {
  background-color: #00aaaa;
}

.popup-btn1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.popup-cont1 h1 {
  padding: 12px 0 !important;
  letter-spacing: 1px;
}

.popup-cont1 p {
  font-weight: 400;
  font-size: 16px;
  color: #061e46;
}

#h2 {
  font-size: 16px;
  width: 112px;
  height: 32px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  margin: 20px 0;
}

#h2:hover {
  color: #ac3929;
  text-decoration-line: underline;
  cursor: pointer;
}

.close-btn1 svg {
  transition: all 0.3s ease;
}

.close-btn1 svg:hover {
  background-color: #EAEDF2;
  border-radius: 100px;
  padding: 2px;
} 