
.main-container {
  min-height: 100vh;
  position: center;
}

.svg-box {
  margin-top: 20px !important;
}

.acc-box {
  display: flex;
  flex-direction: column;
  width: 912px !important;
  height: 632px !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

#pageRow {
  width: 100%;
}

.account-cont {
  display: grid;
  min-width: 100%;
  min-height: 100%;
  width: 912px;
  height: 632px;
  left: 456px; 
}

.account-cont > h1{
  padding-top: 64px;
}

.account-cont h1 {
  font-size: 22px;
  color: rgba(6, 30, 70, 1);
  padding-bottom: 10px;
  font-weight: 600;
  margin-bottom: 14px;
  position: sticky;
  margin-right: 750px;
  white-space: nowrap;
  text-align: center;
}


.box-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px #F1F3F6 solid;
  width: 912px;
  height: 76px;
  padding: 70px 30px 30px 30px;
}


.box-heading h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #17172d;

}

.box-heading h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}

.box-creds {
  width: 564px;
  margin-bottom: 20px;
  margin-top: 12px;
  margin-left: 10px;
}

.credentials {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}

.email-cont,
.password-cont {
  display: flex;
  flex-direction: column;
}
.email-cont {
  margin-bottom: 10px;
}
.password-cont {
  margin-bottom: 5px;
}

.email-cont input {
  background-color: rgba(255, 255, 255, 1);
}

.password-cont  input {
  background-color:rgba(255, 255, 255, 1);
  color: rgba(201, 207, 219, 1);
}

.password-btn-cont {
  display: flex;
}

.credentials label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 17px 0;
}
.credentials input {
  width: 366px;
  height: 28px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  border-bottom: #F1F3F6 solid;
}

.password-btn {
  background: #ffffff;
  border: 1px solid #c9cfdb;
  width: 150px;
  height: 32px;
  border-radius: 100px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: 550;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}


.password-btn:hover {
  color: white;
  background-color: #009999 ;
}

#plan {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  padding: 0 !important;
  font-weight: 600;
}
.plan-cont {
  margin-bottom: 10px;
}

.plan-box {
  border: 1px solid #c9cfdb;
  border-radius: 10px;
  width: 532px;
  height: 64px;
  flex-wrap: wrap;
}
.plan-box div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 26px 20px 20px;

  flex-direction: row;
}

.plan-box h3 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.plan-box p {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.credit-cont {
  display: flex;
  flex-direction: column;
}
.credit-btn-cont {
  display: flex;
}

.credit-btn-cont input {
  background-color: rgba(255, 255, 255, 1);
}

.credit-cont label {
  font-size: 16px;
  font-weight: 600;
  padding: 17px 0;
}
.credit-cont input {
  width: 363px;
  height: 28px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  border-bottom: 2px #F1F3F6 solid;
}

.credit-btn {
  width: 150px;
  height: 32px;
  margin-left: 20px;
  background: #ffffff;
  border: 1px solid #c9cfdb;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  transition: all 0.2s ease-in-out;
}
.credit-btn:hover {
  color: white;
  background-color: #009999 ;
}

.box-footer {
  border-top: 2px #F1F3F6 solid;
  padding: 11px 0px 30px 10px;
  height: 180px !important;


}

#h1-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 199px;
  padding: 9.6px 0 9.6px 0 !important;
  margin-left: 28px;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  margin-top: 13px;
}
#h1-main h1 {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

#h1-main:hover {
  background-color: #ffe1d6;
}

@media only screen and (max-width:767px) {
  .account-cont {
    top: 10% !important;
    left: 0 !important;
    transform: none !important;
    width:100%;
  }

  .account-cont > h1 {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .acc-box {
    box-shadow: none;
    border-radius:0;
    padding: 0 !important;
  }

  .box-heading {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
    height:auto !important;
    border-bottom: none;
  }

  .box-heading > h1 {
    font-size: 6px;
  }

  .box-creds {
    margin: 0;
    width: 100%;
  }

  .credentials {
    padding: 0 24px;
  }

  .email-cont {
    margin-bottom: 0;
  }

  .credentials div {
    padding-bottom: 24px;
  }

  .credentials label {
    padding: 0 !important;
    margin-bottom: 8px;
  }

  .credentials input {
    width: 100%;
  }

  .password-cont {
    margin: 0;
  }

  .password-btn-cont {
    padding: 0 !important;
    flex-direction: column;
  }

  .password-btn {
    margin: 0;
    margin-top: 16px;
  }

  .plan-cont {
    margin: 0;
  }

  .plan-cont .plan-box {
    margin: 0;
    flex-direction: row;
    width: 100%;
  }

  .plan-box div {
    padding: 0;
  }

  .credit-btn-cont {
    margin-top: 16px;
    padding: 0 !important;
    flex-direction: column;
  }

  .credit-btn {
    position: static;
    margin: 0;
    margin-top: 16px;
  }

  #pageRow {
    margin: 0 !important;
  }

  #h1-main {
    padding: 0 !important;
    margin: 0 !important;
    width: auto;
  }

  #h1-main h1 {
    padding: 8px 16px !important;
    margin: 24px auto 16px 24px !important;
  }
}