.searchbar .searchbar__inner {
  padding: 21px 30px;
  background-color: #f0f5fc;
}

.searchbar .input-group {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.searchbar .form-control {
  height: 55px;
  border: 0;
}


.searchbar .form-control input:focus {
  outline: none;
}

.searchbar .btn-primary {
  padding: 15px 48px;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #0162cc;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  position: relative;
}

.searchbar .box__inner ul {
  list-style: none;
  text-align: left;
  padding: 10px;
}
