.main-container {
  min-height: 110vh;
  position: relative;
}

.svg-box {
  margin-top: 2px;
}
.main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-content h1 {
  font-weight: 600;
  font-size: 24px;
  color: #061E46;
  line-height: 32px;
  padding-bottom: 24px;
}

.content {
  width: 912px;
  height: 326px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 30px;
}

.content input:focus-visible {
  outline: none !important;
}

.new-password-cont,
.new-password-cont1 label {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
}
.new-password-cont input {
  width: 60%;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  border: none;
  margin: 1px 0;
  border-bottom: rgba(192, 192, 192, 0.507) solid;
  transition: all 0.3s ease;
}
.new-password-cont input:hover {
  border-bottom: 2px solid #009999;
}
.new-password-cont1 input:hover {
  border-bottom: 2px solid #009999;
}
.new-password-cont1 input {
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  border: none;
  border-bottom: rgba(192, 192, 192, 0.507) solid;
  transition: all 0.3s ease;
}
span {
  color: #ac3929;
}
.new-password-cont,
.new-password-cont1 {
  display: flex;
  flex-direction: column;
}

.updatePass-btn-cont {
  margin-top: 42px;
}

.updatePass-btn {
  width: 156px;
  background: #009999;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 16px;
  border-radius: 100px;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px; 
  line-height:16px;
}

.updatePass-btn:hover {
  background-color: #1aaca9;
}

@media only screen and (max-width:767px) {
  .main-content {
    left: 0;
    top: 12%;
    transform:none;
    width:100%;
    height:100%;
  }
  
  .content-sub-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .updatePass-btn-cont {
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 24px;
  }

}