* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Hind";
  font-style: normal;
}

.App {
  font-family: sans-serif;

}
#header {
  width: 100% !important;
  height: 96px !important;
  min-width: 100%;

  background: #009999;
  padding: 0 20px;
  font-family: "Hind";
}

.header_main {
  display: flex;
  flex-wrap: nowrap;

  width: 100%;
  min-width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.header_comp1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  min-width: 70%;
  height: 40px;
}

body {
  font-family: "Hind", sans-serif;
  color: "#333";
  overflow-y: hidden;

}

.body-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 100px !important;
  /* min-height: 100%;
  min-width: 100%; */
  /* height: ; */
  position: absolute;
  width: 1859px;
  height: 820px;
  min-height: 820px;
  min-width: 1659px;
}
.body-section .body-section-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  width: 324px;
  height: 1659px;
  /* margin-right: 25px; */
}
.body-section .tui-calendar-react-root .tui-full-calendar-layout .tui-view-1 {
  /* display: flex; */
  /* min-height: 50%;
  max-height: 50%;
  min-width: 80%; */
  /* position: absolute; */
   /* width: 1835px;  */
    /* height: 1076px;  */

}

.body-section-left {
  position: relative;
}

.my-hidden-modal {

    display: flex;
    flex-direction: column;
    
    border-radius: 10px !important;
     /* width: 680px;
    height: 760px; */
    overflow: hidden;
    font-family: "Hind";
    /* font-style: normal; */
    /* font-weight: 600; */
    /* position: absolute;

    top: 50%;
    left: 50%;  */



}

.my-hidden-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
/* .my-hidden-modal-body .MuiButton-root {
  left: 497px;

} */
.my-hidden-modal-body .MuiButton-root:hover {
  left: 497px;
  background-color: #1aaca9;

}

.my-hidden-modal-body .save-button {
  left: 497px;
  /* background-color: #a0a0a0; */
  border-radius: 10px;
  border-color: #0E0E2C;
}

.my-hidden-modal-body .save-button:hover {
  left: 497px;
  background-color: #1aaca9;

}

.my-hidden-modal-body-header {
  /* background-color: #e5e5e5; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 20; */
  align-items: "center";
  padding-top: 50px;
  overflow: hidden;
  /* grid-row-start: 1; */
  justify-self:center;
  /* width: 680px; */

}
.my-hidden-modal-body-header .MuiGrid-container {
  width: 720px;

}
.my-hidden-modal-body-header > IconButton {
  justify-self: right;
}
.my-hidden-modal-body-header .MuiInputBase-input {
  width: 100px;
}
.my-hidden-modal-main-content .MuiInput-input {
  width: 550px;
  letter-spacing: 0;
  letter-spacing: 0;
}
/* .my-hidden-modal-body-header .MuiInputBase-input:hover {
  border-color: #1AACA9;
} */
.my-hidden-modal-body-header .select-hover {
  border-radius: 15px;
  width: 328px;
}

/* .dropdownMenu  {
  transform: translate(-10px, -90px)
} */

/* .my-hidden-modal-body-header .select-hover:hover {
  border-color: rgb(55, 255, 0) !important
  
} */

/* .menu-item-select {
  width: 150px;
} */
/* .menu-item-select:hover {
  background-color: aqua;
  border-color: rgb(0, 255, 119);
} */

.my-hidden-modal-main-content {

  color: #0e0e2c;
  /* padding: 40; */
}
.my-hidden-modal-body-header .MuiIconButton-label {
  padding-bottom: 60px;
}
.my-setDate {
  width: 80px;
}
.my-hidden-modal-main-content .my-grid-dates {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* color: black; */
}
.my-hidden-modal-main-content .my-grid-dates > span{
  color: black;
}
.my-hidden-modal-main-content .my-grid-dates-select {
  padding-left: 10px;
  width: 190px;
  padding-bottom: 3px;
}
.my-hidden-modal-main-content .my-grid-meeting {
  /* padding-left: 50px; */
  width: 600px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.my-hidden-modal-main-content .my-grid-meeting-input {
  /* padding-left: 50px; */
  width: 455px;

  /* justify-content: center; */
  /* align-items: center; */
}
.my-hidden-modal-main-content .my-grid-meeting-buttons {
  /* padding-left: 50px; */
  /* width: 555px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-hidden-modal-main-content .my-grid-meeting-input {
  /* padding-left: 50px; */
  width: 555px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* text-overflow: nowrap; */
}
.my-hidden-modal-main-content .meetingLink:hover {
  background-color: #009999;
  cursor: pointer;
}
.my-hidden-modal-main-content .my-grid-privacy {
  /* padding-left: 50px; */
  width: 555px;
  padding-left: 50px;
  margin-top: 20px;
  /* justify-content: center; */
  /* align-items: center; */
}
.my-hidden-modal-main-content .my-hidden-button-event {
  /* padding-left: 50px; */
  color:#008581;
  /* justify-content: center; */
  /* align-items: center; */
}
.my-hidden-modal-main-content .my-grid-privacy-select {
  /* padding-left: 50px; */
  display: flex;
  padding-left: 150px;

  justify-content: center;
  align-items: center;
}
.my-hidden-modal-main-content .my-color-scheme-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* padding: 10px; */
}
.my-hidden-modal-main-content .my-color-scheme-container > input:hover {
  color:#009999

}

.my-hidden-modal-secondary-container {
  /* padding: 20px 25px; */
  background-color: #fff;
}

/* .my-hidden-modal-grid {
  padding: 0;
  /* width: 580px;
  height: 660px; */
  /* overflow: hidden;
  width: 680px;
  height: 760px;


} */ 
.my-hidden-modal-typhoraphy {
  color: #333;
}

.move-today {
  border-radius: 100px !important;
  background: #ffffff;
  border: 1px solid #c9cfdb;
  border-radius: 100px;
  outline: none;
  padding: 8px 8px 8px 12px;
  gap: 4px;
  width: 76px;
  height: 40px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #0e0e2c;
}

.move-today:hover {
  cursor: pointer;
}

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none;
}

.open > .dropdown-toggle.btn-default {
  background-color: #fff;
}

.dropdown-menu {
  top: 25px;
  padding: 3px 0;
  border-radius: 2px;
  border: 1px solid #bbb;
}

.dropdown-menu > li > a {
  padding: 9px 12px;
  cursor: pointer;
}

.dropdown-menu > li > a:hover {
  background-color: rgba(81, 92, 230, 0.05);
  color: #333;
}


.sdp {
  padding: 0px !important;
  font-size: 10px !important;
  line-height: 10px !important;
  margin-right: 2rem !important;
  margin-left: 2rem !important;
  margin-top: 80px !important;
  margin-bottom: 32px !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25) !important;
  min-height:220px !important;
  width: 264px;
  height: 260px;
}

.sdp--month-picker {
  padding: 8px 16px !important;
  display: flex !important;
  flex-direction:row !important;
}

.sdp--dates-grid {
  padding: 0px 16px 8px 16px !important;
  margin-top: 0px !important;
  gap: 0px !important;
  min-height: 170px;
  min-width: 150px;
}

.sdp--dates-grid > p.sdp--text {
  display: inline-block;
  visibility: hidden !important;
}

.sdp--dates-grid > p.sdp--text:nth-child(1)::before {
  visibility: visible;
  content: "S";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text:nth-child(2)::before {
  visibility: visible;
  content: "M";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text:nth-child(3)::before {
  visibility: visible;
  content: "T";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text:nth-child(4)::before {
  visibility: visible;
  content: "W";
  position: relative;
  left: 30%;
}

.sdp--dates-grid > p.sdp--text:nth-child(5)::before {
  visibility: visible;
  content: "T";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text:nth-child(6)::before {
  visibility: visible;
  content: "F";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text:nth-child(7)::before {
  visibility: visible;
  content: "S";
  position: relative;
  left: 35%;
}

.sdp--dates-grid > p.sdp--text {
  padding: 0 0 0.5rem 0 !important;
}

.sdp--square-btn svg {
  background-color: #fff !important;
  color: #009999 !important;
}
.sdp--date-btn__selected {
  background-color: #009999 !important;
  border-radius: 100px !important;
}

.sdp--month-name {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  order: 1 !important;
}

.sdp--square-btn__shadowed {
  box-shadow: none !important;
}

.sdp--square-btn__shadowed:nth-child(1) {
  order: 2 !important;
  margin-right: -10px !important;
  width: 31px !important;
  height: 31px !important;
  padding: 5.5px !important;
  border-radius: 100px !important;

}

.sdp--square-btn__shadowed:nth-child(3) {
  order: 3 !important;
  margin-left: -10px !important;
  width: 31px !important;
  height: 31px !important;
  padding: 5.5px !important;
  border-radius: 100px !important;
}

.sdp--text__inactive {
  text-transform: uppercase !important;
}

.bi15 {
  width: 15px;
  height: 15px;
}

.calendar-icon {
  width: 14px;
  height: 14px;
}

#top {
  height: 49px;
  border-bottom: 1px solid #bbb;
  padding: 16px;
  font-size: 10px;
}

#lnb {
  position: absolute;
  width: 200px;
  top: 49px;
  bottom: 0;
  border-right: 1px solid #d5d5d5;
  padding: 12px 10px;
  background: #fafafa;
}

#right {
  position: absolute;
  left: 200px;
  top: 49px;
  right: 0;
  bottom: 0;
}

#lnb label {
  margin-bottom: 0;
  cursor: pointer;
}

.lnb-new-schedule {
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e5e5;
}

.lnb-new-schedule-btn {
  height: 100%;
  font-size: 14px;
  background-color: #ff6618;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-new-schedule-btn:hover {
  height: 100%;
  font-size: 14px;
  background-color: #e55b15;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-new-schedule-btn:active {
  height: 100%;
  font-size: 14px;
  background-color: #d95614;
  color: #ffffff;
  border: 0;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
}

.lnb-calendars > div {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
}

.lnb-calendars-d1 {
  padding-left: 8px;
}

.lnb-calendars-d1 label {
  font-weight: normal;
}

.lnb-calendars-item {
  min-height: 14px;
  line-height: 14px;
  padding: 8px 0;
}

.lnb-footer {
  color: #999;
  font-size: 11px;
  position: absolute;
  bottom: 12px;
  padding-left: 16px;
}

#menu {
  padding: 16px;
}

#dropdownMenu-calendarType {
  padding: 0 8px 0 11px;
}

#calendarTypeName {
  min-width: 62px;
  display: inline-block;
  text-align: left;
  line-height: 30px;
}

.dropdown-menu-title .calendar-icon {
  margin-right: 8px;
}

.calendar-bar {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #eee;
  vertical-align: middle;
}

.calendar-name {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
}

.schedule-time {
  color: #005aff;
}

#calendar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  top: 64px;
}


.emails svg {
  margin-right: 7px;
}

.header_comp1_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  min-width: 720px;
  min-height: 40px;
  margin-left: 130px;
}

.arrow-left,
.arrow-right {
  margin: 10px 0px;
  cursor: pointer;
  background-color: #099;
  border-radius: 100px;
  border: none;
}

.arrow-left {
  margin-left: 30px;
  margin-right: 15px;
}

.arrow-right {
  margin-right: 30px;
  margin-left: 15px;
}

.cw-btn button:hover {
  background-color: #1AACA9 !important;
  border-radius: 100px !important;
}

.header_comp1_inner svg {
  width: 27px;
  height: 27px;
  padding: 5px;
  pointer-events:none;
}

.today-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  max-width: 200px;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
  min-width: 90px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c9cfdb;
  color: #0E0E2C;
  align-self: 'flex-start'; 

  border-radius: 100px;
  transition: all 0.3s ease;
}
.today-btn:hover {
  background-color: #1aaca9;
  border: 1px #1aaca9 solid;
  color: white;
}

.select-cont {
  max-width: 130px;
  height: 40px;

  background: #ffffff !important;
  border-radius: 100px;
  align-self: 'flex-start'; 
  min-width: 90px;
  align-items: center;
  transition: all 0.4s ease;
  color: #0E0E2C;
}
.select-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.0rem;
  margin-right: 0.7rem;  
  flex-grow: 2;
  text-align: center;

}

.view-select-cust {
  width: 129px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  -moz-appearance:none; 
  -webkit-appearance:none; 
  appearance: none;
  background: transparent;

  align-items: center;
  border: none;
  cursor: pointer;
  color: #0E0E2C !important;
 }

 .view-select-cust:focus-visible{
  border: none;
  outline: none;
 }

 .view-option-cust {
  text-align: left;
  border-radius: 100px;
  color: black !important;
 }

 .select-cont:hover {


  align-items: center;
  color: white;
  cursor: pointer;
  background-color: #1aaca9 !important; 
  border: 1px #1aaca9 solid;
}

 .select-cont:hover .view-select-cust {
  font-weight: 600;

  -moz-appearance:none; 
  -webkit-appearance:none;
  appearance:none;
  color: white !important; 
  flex-grow: 2;
 }

.sync-svg {
  width: 20px;
  height: 10px;
  cursor: pointer;
}

.div span {
  color: black !important;
}

.sync-btn-cont {
  width: 78px;
  height: 40px;
  margin-right: 135px;
}
.sync-btn {
  width: 118px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c9cfdb;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
}
.sync-btn:hover {
  background-color: #1aaca9;
  border: 1px #1aaca9 solid;
  color: white;
}

.acc-btn-cont {
  width: 118px;
  height: 40px;
  margin-right: 12px;
}

.account-btn {
  width: 118px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c9cfdb;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
}
.account-btn:hover {
  background-color: #1aaca9;
  border: 1px #1aaca9 solid;
  color: white;
}
.logo {
  padding-top: 15px;
  padding-left: 12px;
}

.header_comp1_inner .render-range {
  min-width: 253px;
  min-height: 32px;
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  padding: 0px;
  padding-left: 12px;
  vertical-align: middle;
}

.header_comp1_inner .view-select {
  height: 40px;
  padding: 8px 8px 8px 12px;
  gap: 4px;

  background: #ffffff;
  border-radius: 100px;

  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;

  outline: none;
}

.header_comp1_inner .view-select:hover {
  cursor: pointer;
}

.header_comp1_inner .view-select-option:hover {
  cursor: pointer;
}

.dashDsgn {
  position: absolute;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 1rem !important;
}

.move-day {
  padding: 0px;
}

.accountList {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 8px 0px 8px;
  margin-left: 2rem;
  width: 260px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background: #f1f3f6;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height:192px !important;
  max-height: 433px;
  overflow-y: scroll;
  overflow-x: hidden;
}



.dropdown {
  font-family: "Hind" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin: 16px 32.5px 24px 32.5px;
}

.dropdown button {
  background: #009999;
  border-radius: 100px;
}
/* .success.dropdown-toggle {
  background-color: ;
} */

.dropdown-menu {
  margin-top: 10px !important;
}
.dropdown-item {
  padding: 10px 20px !important;
}
.dropdown-menu a {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
}

/* .my-hidden-modal-body .MuiGrid-container {
  width: 720px;

}.MuiInputBase-root {
  letter-spacing: 0;
} */

.account-List-section {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
  border-bottom: 1px #bbb solid;
}

.account-List-section  p {
  margin-bottom: 0.5rem;
}

.account-List-section .heading {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 24px;
  text-align:center;
  margin-bottom: 0;
}

.account-list-section-services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.account-list-section-services > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.account-list-section-services p {
  font-size: 16px;

}

.google-section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 32px;
  padding: 4px !important;
  transition: all 0.3s ease;
  cursor: pointer;
}
.google-section-heading:hover {
  background: white;
  border-radius: 10px;
}
.google-section-heading:hover .set-div {
  background: url("../Logo/setting1.png");
  width: 22px !important;
  height: 22px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: 13%;
}

.outlook-section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 32px;
  padding: 4px !important;
  transition: all 0.3s ease;
  cursor: pointer;
}
.outlook-section-heading:hover {
  background: white;
  border-radius: 8px;
}
.outlook-section-heading:hover .set-div {
  background: url("../Logo/setting1.png");
  width: 22px !important;
  height: 22px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.apple-section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 32px;
  padding: 4px !important;
  transition: all 0.3s ease;
  cursor: pointer;
}
.apple-section-heading:hover {
  background: white;
  border-radius: 8px;
}
.apple-section-heading:hover .set-div {
  background: url("../Logo/setting1.png");
  width: 22px !important;
  height: 22px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.google-section-heading .h5 {
  padding: 0 !important;
  line-height: 0 !important;
  margin: 0 !important;
  padding-left: 0.2rem !important;
}
.apple-section-heading .h5 {
  padding: 0 !important;
  line-height: 0 !important;
  margin: 0 !important;
  padding-left: 0.2rem !important;
}
.outlook-section-heading .h5 {
  padding: 0 !important;
  line-height: 0 !important;
  margin: 0 !important;
  padding-left: 0.2rem !important;
}

.accountList .h5 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}



.accountList .list {
  margin-bottom: 16px;
  padding: 0px;
}


.list .emails {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  padding-top: 0.5rem;
  padding-left: 5px;
  color: #0e0e2c;
}

.emails .bi {
  margin: 10px !important;
  padding: 1px 3px;
  border: 1px;
  color: white;
  font-size: 12px;
  border-radius: 100px;
}

.google-section .emails .bi {
  background: #1aaca9;
}
.outlook-section .emails .bi {
  background: #6389b0;
}
.appleSection .emails bi {
  background: #f46745;
}
.addCalendar .dropdown-item span {
  padding-right: 5px;
}

#account-part {
  height: 40px;
  padding: 6px 6px 8px 12px;
  gap: 4px;

  background: #ffffff;
  border-radius: 100px;

  font-family: "Hind";
  font-style: normal;

  font-size: 14px;
  line-height: 12px;

  outline: none;
}

#account-part button {
  border: none !important;
  margin-top: 7px;
  margin-right: 5px;
  font-weight: 800 !important;
}

.frames svg {
  position: absolute;
  right: 12%;
  width: 179px !important;
  height: 160px !important;
  top: -5% !important;
  transform: rotate(-3deg);
}

.set-div {
  background: url("../Logo/setting.png");
  width: 22px !important;
  height: 22px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease;
  border-radius: 100% !important;
  cursor: pointer;
  position: fixed;
  left: 13%;
}

.set-div:hover {
  background: url("../Logo/setting1.png");
  width: 22px !important;
  height: 22px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease;
  border-radius: 100% !important;
  cursor: pointer;
  position: fixed;
  left: 13%;
}

.accordion-item {
  border: none !important;
  border-radius: 30px !important;
  background: #f1f3f6 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;

}

.dtoggle {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 16px 24px !important;
  gap: 8px !important;
  box-sizing: border-box !important;
  background-color: #008581 !important;
  border: 1px solid #C9CFDB !important;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 100px !important;
  width: 167px;
  height: 48px;
  min-width: 167px;
  min-height: 48px;
  font-size: 16px !important;
}

.dtoggle:hover{
  background-color: #1AACA9 !important;
}

.dtoggleSettings {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  box-sizing: border-box !important;
  background-color: #FFFFFF !important;
  /* border: 1px solid #C9CFDB !important; */
  /* box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25) !important; */
  border-radius: 100px !important;
  min-width: 100px;
  min-height: 40px;
  font-size: 16px !important;
  height: 40px;
  margin-right: 135px;
  width: 100px;
}

.dtoggleSettings:hover{
  background-color: #1AACA9 !important;
}

.ddown h1 {
  font-size: 16px;
  padding-top: 8px;
  font-family: "Hind";	
  font-style: normal;
  color: #FFFFFF;
}
/* .ddownsettings {
  background-color: #FFFFFF;
  color: #FFFFFF;

}
.ddownsettings:hover {
  background-color: #1AACA9;
  color: #FFFFFF;

} */
.ddownsettings h1 {
  font-size: 16px;
  padding-top: 10px;
  padding-left: 10px;
  font-family: "Hind";	
  font-style: normal;
  color: #202020;
  /* color: #FFFFFF; */

}

.ddownsettings h1:hover {
  color: #FFFFFF;
}

.dropdownMenu {
  border-radius: 10px !important;
  width: 216px !important;
  height:160px !important;
  box-shadow:0px 4px 15px 4px rgb(0 0 0 / 25%) !important;
  padding: 8px, 0px, 8px, 0px !important;
  margin: 8px -25px 8px -25px !important;
  transform: translate(-10px, 15px) !important;
  position: relative !important;


}

.dropdownMenuSettings {
  display: flex;
  border-radius: 10px !important;
  width: 150px;
  height: 108px;
  /* box-shadow:0px 4px 15px 4px rgb(0 0 0 / 25%) !important; */
  padding: 8px, 0px, 8px, 0px !important;
  margin: 8px -25px 8px -25px !important;
  background-color: #FFFFFF;
  align-items: flex-start;
  position: relative;

}

.MuiIconButton-label span {
  color: #9b9b9b !important;
}

#div {
  display: flex !important;
  flex-direction: row !important;
  justify-content:start;
  height: 48px !important;  
}

.optionTitle {
  font-weight: 500;
  margin-left: 8px !important;
}


.accordion-body {
  padding: 0 !important;
  width: 216px;
height: 160px;

}
.accordion-body > div {
  background: white !important;
  border-radius: 10px !important;
  margin-top: 20px;

}

.accordion-button:not(.collapsed) {
  background: #008581 !important;
  color: white !important;
 
}

.accordion-button {
  background-color: #008581 !important;
  font-weight: 500 !important;
  color: white !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.3s ease;
}
.accordion-button:hover {
  background-color: #1aaca9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}
.accordion-button::after {
  background: url("../Logo/caratdown.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 10px !important;
height: 6px !important;
  margin: 0 !important;
  margin-left: 10px !important;
}

.accordion-button:not(.collapsed::after) {
  color: white !important;
}

.accordion-header {
  width: 12rem !important;
  margin-right: 0 !important;
  text-align: center;
  color: #FFFFFF;
}

.accordion .calendar {
  width: 100%;
  height: 50px;
}

.accordion .calendar a {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  text-decoration: none;
}

.accordion .appleCalendar span {
  margin-right: 10px !important;
}

.accordion .calendar:hover {
  background: #eaedf2;
}

.accordion-button {
  border-radius: 100px !important;
  outline: none !important;
}

.tui-full-calendar-timegrid-gridline {
  height: 56px !important;
}

.tui-full-calendar-weekday-border {
  border: none !important;
}

.tui-full-calendar-month-week-item {
  border: 1px solid #DDE3EB !important;
  border-bottom: none !important;
}

.tui-full-calendar-month-week-item:first-child {
  border-radius: 10px 10px 0 0 !important;
}

.tui-full-calendar-month-week-item:last-child {
  border-radius: 0 0 10px 10px !important;
  border-bottom: 1px solid #DDE3EB !important;
}

.tui-full-calendar-month-week-item
  .tui-full-calendar-today
  .tui-full-calendar-weekday-grid-date-decorator {
  background-color: #009688 !important;
  color: white !important;
  font-weight: 400 !important;
  margin: 5px 5px 0 0;
  padding: 0 !important;
}

/* .tui-full-calendar-timegrid-sticky-container {
  width: 180px;

} */

/* .tui-full-calendar-timegrid-timezone-label-container {
  font-size: 9px;
  width: 20%;

} */
/* .tui-full-calendar-timegrid-right {
  right: -8%;
}
.tui-full-calendar-timegrid-left {
  right: 89%;
  width: 200px;
} */

/* .calendar {
  margin-left: 66px;
  height: 90%;
  width: 79%;
} */

.tui-calendar-react-root .tui-full-calendar-layout:first-child {
  font-family: "Hind", sans-serif !important;
  font-weight: 400 !important;
}

.tui-calendar-react-root {
  flex: 1 !important;
  border-radius: 20px !important;
  margin-top: 1rem;
  margin-right: 2.031rem;
  min-width: 80%;
  position: static !important;
  height: 100% !important;
  clear: both;
}

.tui-full-calendar-weekday-grid-date {
  color: #0E0E2C !important;
  padding: 5px 13px 0 0 !important;
}

.tui-full-calendar-month-dayname {
  font-size: 15px !important;
}

.tui-full-calendar-dayname {
  display: flex !important;
  flex-direction:column !important;
  border: none !important;
}

.tui-full-calendar-dayname-container {
  border: none !important;
  height: 8vh !important;
  border-top: 0px !important;
  overflow-y: hidden !important;
  padding: 0px 10px 0px 0px;
  min-width: 100%;
  /* margin-left: 120px; */
  
}

.tui-full-calendar-timegrid-container {
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
}

.tui-full-calendar-timegrid-container::-webkit-scrollbar {
  width: 10px;
}

.tui-full-calendar-timegrid-container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.tui-full-calendar-timegrid-container::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #e4e8ee;
}

.tui-full-calendar-timegrid-right {
  border: 1px solid #dde3eb !important;
  border-radius: 10px;
  margin-left: 50px !important;
  margin-right: 8px !important;
}

.tui-full-calendar-dayname-date-area {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 10px !important;
  order: 1 !important;
  margin-right: 25px;
  padding-top: 15px;
  min-width: 100%;
  min-height: 100%;
}

.tui-full-calendar-dayname-date {
  order: 3 !important;
  font-weight: 200 !important;
  transition: all 0.3s ease !important;
  margin-bottom: 10px !important;
  color: rgba(0, 0, 0, 0.796) !important;
}

.tui-full-calendar-dayname-date:hover {
  background-color: rgb(235, 235, 235) !important;
  border-radius: 100px !important;
}

.tui-full-calendar-dayname-name {
  order: 2 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.711) !important;
  text-transform: uppercase;
  font-weight:  normal !important;
  margin-bottom: 10px;
  margin-right: 15.5px !important;
}

.tui-full-calendar-today .tui-full-calendar-dayname-name {
  margin-right: 8px !important;
}

.tui-full-calendar-today .tui-full-calendar-dayname-date {
  background-color:  #009999 !important;
  border-radius: 100px !important;
  height: 42px !important;
  width: 42px !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 0px !important;
}

.tui-full-calendar-timegrid-timezone {
  border: none !important;
  background-color: #fff !important;
  width: 40% !important;

}



.tui-full-calendar-dayname-leftmargin {
  margin-left: 50px !important;
  margin-right: 8px !important;
}

.tui-full-calendar-time-date-schedule-block-wrap {
  margin-right: 0 !important;
}

.tui-full-calendar-holiday-sun,
.tui-full-calendar-holiday-sat {
    background-color: inherit !important;
    color: black !important;
}

.tui-full-calendar-near-month-day {
  display:flex;
  justify-content: end;
}

.tui-full-calendar-timegrid-hour {
  text-transform: uppercase; 
  height: 56px !important;
}

.tui-full-calendar-timegrid-hourmarker-line-left {
  border-top: 1px solid rgb(251, 136, 102,1) !important;
}

.tui-full-calendar-timegrid-hourmarker-line-today, .tui-full-calendar-timegrid-hourmarker-line-right {
  border-top: 2px solid rgba(251, 136, 102, 200) !important;
}

.tui-full-calendar-timegrid-todaymarker {
  background-color: rgba(251, 136, 102, 200) !important;
}

.tui-full-calendar-timegrid-hourmarker-time {
  color: rgba(251, 136, 102, 200) !important;
}

.tui-full-calendar-time-schedule {
  border-radius: 6px !important;
  margin-right: 9px !important;
  margin-left: 1px !important;
}

.tui-full-calendar-time-schedule-content {
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px;
}

.tui-full-calendar-timegrid-hour:first-child span {
  display:inline-block !important;
  visibility: hidden !important;
}

.tui-full-calendar-timegrid-hour:first-child span::before {
  position: absolute !important;
  left: 3px !important;
  margin-top: 6px;
  visibility: visible !important;
  content: "12 am" !important;
}

.tui-full-calendar-timegrid-hour span {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #0E0E2C;
}

.tui-full-calendar-month-dayname {
  border-top: none !important;
  border-bottom: none !important;
}

.tui-full-calendar-month-dayname-item > span {
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.tui-full-calendar-extra-date .tui-full-calendar-weekday-grid-header .tui-full-calendar-weekday-grid-date{
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(14, 14, 44, 0.5) !important;
}

.tui-full-calendar-content {
  color: #212529;
}
.tui-full-calendar-section-button{
  font-size: 26px;

}

.tui-full-calendar-popup-edit {
  background-color: #009999;
  border-radius: 25px;
  border: 1px solid #009999;
  
}

.tui-full-calendar-popup-edit:hover {
  background-color: #1aaca9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.tui-full-calendar-popup-edit span {
  color: white;
  font-size: 14px;
}
.tui-full-calendar-popup-delete {
  background-color: #009999;
  border-radius: 25px;
  border: 1px solid #009999;
} 
.tui-full-calendar-popup-delete:hover {
  background-color: #1aaca9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}
.tui-full-calendar-popup-delete span {
  color: #ffffff;

} 
.tui-full-calendar-timegrid-timezone-label div {
  margin-right: 200px;
}
.tui-full-calendar-schedule-title {
  color: #212529;

}
.tui-full-calendar-content {
  color:#212529;
}
.tui-full-calendar-popup {
  border-radius: 20px;
  box-shadow: #0E0E2C;
}
.tui-full-calendar-popup-top-line {
  background-color: transparent;
}
.tui-full-calendar-popup-container {
  border-radius: 20px;
  box-shadow: #0E0E2C;
  width: 301px;
  height: 165px;

}

.tui-full-calendar-popup-container .tui-full-calendar-section-button-save > button {
  background-color: #009999;
  border-radius: 20px;
}
.tui-full-calendar-popup-container .tui-full-calendar-section-button-save > button:hover {
  background-color: #1aaca9;
}
.tui-full-calendar-popup-container .tui-full-calendar-section-button-save > button > span {
  color:#FFFFFF;
  font-size: 14px;
  font-weight: 600;
}

.settings {
  border: none;
}

@media only screen and (max-width: 767px) {
  .sdp {
    padding: 0px !important;
    font-size: 10px !important;
    line-height: 10px !important;
    margin-right: 2rem !important;
    margin-left: 2rem !important;
    margin-top: 80px !important;
    margin-bottom: 32px !important;
    font-size: 12px !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25) !important;
    min-height:220px !important;
    width: 264px;
    height: 260px;
  }
}