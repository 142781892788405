.footer {
    background-color: #061E46;
}
.buttons {
    color: #061E46;
    padding: 7px 15px;
    font-size:17px;
    font-weight:600;
    border: none;
    border-radius:500px;
    -webkit-border-radius:500px;
    -moz-border-radius:500px;
    -ms-border-radius:500px;
    -o-border-radius:500px;
    margin: 0 10px;
}
.sync {
    background-color: #CC4736;
    color: white;
}
a{
    color: black !important;
}