.background {
  padding: 70px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.heading {
  color: #3a4468;
}
.contentWrapper {
  padding: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 15px 15px 15px;
  -webkit-border-radius: 0px 15px 15px 15px;
  -moz-border-radius: 0px 15px 15px 15px;
  -ms-border-radius: 0px 15px 15px 15px;
  -o-border-radius: 0px 15px 15px 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
.boxhead {
  background-color: #f46745;
  padding: 5px 15px;
  text-align: center;
  color: white;
  display: inline-block;
  font-size: 19px;
  font-weight: bold;
  border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -ms-border-radius: 15px 15px 0 0;
  -o-border-radius: 15px 15px 0 0;

}

.box2Color {
  background-color: #009999;
}
