.innerPopup {
  width: 848px;

}

.close-btn1 {
  margin-right: 30px;
  background-color: white;
}

.innerPopup h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.innerPopup h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-top: 8px;
}

.redirectBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.redSignBtn {
  border: none;
  height: 40px;
  background-color: rgba(0, 153, 153, 1);
  color: white;
  padding: 12px 16px 12px 16px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  border-radius: 100px;
  margin-bottom: 12px;
}

.tryBtnContainer {
  text-decoration: none;
}

.tryBtn {
  margin-top: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.redirectBtnCal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.redSignBtnCal {
  height: 48px;
  background-color: rgba(0, 153, 153, 1);
  color: white;
  padding: 12px 24px 12px 24px !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  border-radius: 100px;
  margin-bottom: 12px;
}
