.heading {
    color: #6389B0;
}

.bannerButton {
    background-color: #F46745;
    color: white;
    margin: 20px 0;
    padding: 7px 25px;
    border: none;
    border-radius:500px;
    -webkit-border-radius:500px;
    -moz-border-radius:500px;
    -ms-border-radius:500px;
    -o-border-radius:500px;
    font-size: 18px;
    font-weight: 500;
}
.orangeColor {
    color: #F46745;
}
.desBanner {
    font-size: 18px;
    font-weight: 500;
}