.modal-dialog {
  max-width: 680px !important;
}

.modal-header {
  height: 80px !important;
  background: #009999 !important;
  justify-content: space-between !important;
  border-radius: 20px 20px 0 0 !important;
}

.modal-title {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: white;
}

.modal-content {
  border-radius: 20px !important;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25) !important;
}

.gct-btn {
  margin-right: -200px !important;
  font-family: Hind !important;
  width: 128px;
  gap: 8px;

  padding: 8px 4px;
  gap: 8px;

  background: #ffffff;

  border: 1px solid #c9cfdb;
  border-radius: 100px;

  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.btn-close {
  margin-left: 0 !important;
}

#hero-h1 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #0e0e2c;
}

.card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-heading .hero-h2 {
  font-size: 1.3rem;
  margin: 0;
}

.card-heading img {
  cursor: pointer;
}

.card {
  font-family: Hind !important;
  border-radius: 10px;
  border: 1px solid #dde3eb;
  position: relative;
  padding: 16px !important;
}

.card-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  font-family: Hind !important;
  overflow-x: scroll;
  overflow-y: hidden !important;
}


.radio-cont {
  margin-left: 16px; 
}

.shareCalendar {
  margin: 1rem 0;
}
.urlShare {
  white-space: nowrap;
  overflow-x: hidden;
  border: 1px solid;
  padding: 8px 4px;
  margin-top: 10px;
  width: 96%;
}

.shareSection span {
  cursor: pointer;
  text-align: center;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 9px;
  margin-top: 7px;
  margin-left: 6px;
  color: black;
}

.shareSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-scheme-cont {
  margin-top: 20px;
  display: flex;
}

.color-btn {
  margin-left: 1px;
}
.color-btn {
  display: flex;
  justify-content: center;
}

.color-btn input {
  outline: none !important;
}
.ck {
  margin-left: 1rem;
  position: relative;
}

.ck1 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #0ba2dd;
  border: 1px solid #0ba2dd;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ck1 input:checked::before {
  background-color: #0ba2dd;
}

.ck1 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck2 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #f46745;
  border: 1px solid #f46745;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.ck2 input:checked::before {
  background-color: #f46745;
}

.ck2 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck3 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #6389b0;
  border: 1px solid #6389b0;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.ck3 input:checked::before {
  background-color: #6389b0;
}

.ck3 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck4 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #1aac57;
  border: 1px solid #1aac57;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ck4 input:checked::before {
  background-color: #1aac57;
}

.ck4 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck5 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #3a1aac;
  border: 1px solid #3a1aac;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ck5 input:checked::before {
  background-color: #3a1aac;
}

.ck5 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck6 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #00fdc6;
  border: 1px solid #00fdc6;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ck6 input:checked::before {
  background-color: #00fdc6;
}

.ck6 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ck7 input::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #eeff00;
  border: 1px solid #eeff00;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ck7 input:checked::before {
  background-color: #3a1aac;
}

.ck7 input:checked::after {
  position: absolute;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7212 0.961339C11.3499 0.589562 10.747 0.589797 10.3752 0.961339L4.31744 7.01934L1.62503 4.32695C1.25325 3.95517 0.650609 3.95517 0.278832 4.32695C-0.0929441 4.69872 -0.0929441 5.30136 0.278832 5.67314L3.6442 9.03851C3.82998 9.22428 4.07357 9.3174 4.31719 9.3174C4.5608 9.3174 4.80463 9.22452 4.9904 9.03851L11.7212 2.30751C12.0929 1.93599 12.0929 1.33309 11.7212 0.961339Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.logout-btn-container {
  padding-right: 0px;
}

.logout-btn-container {
  margin-left: 65%;
}

.log-btn {
  padding: 14px 26px;
  border-radius: 100px;
  font-family: Hind;
  font-weight: 600;
  background-color: white;
  color: black;
}

.log-btn:hover {
  cursor: pointer;
  background-color: #ffefe9;
  color: black;
}

.bi {
  margin-top: 5px;
  margin-left: 9px;
}

.save-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
}
.btn1 {
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  border: none;
  color: #0e0e2c;

  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
}

.btn2 {
  padding: 8px 16px;
  background: #009999;
  color: white;
  border: none;
  border-radius: 100px;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
}

.accounts-container input {
  appearance: auto;
}

.accountList .list {
  list-style-type: none;
}

.emails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left !important;
}

.emails span {
  text-align: left !important;
  color: black !important;
}

.tagify {
  width: 100%;
  max-width: 700px;
}

.tagify--outside {
  border: 0;
}

.tagify--outside .tagify__input {
  order: -1;
  flex: 100%;
  border: 1px solid var(--tags-border-color);
  margin-bottom: 1em;
  transition: 0.1s;
}

.tagify--outside .tagify__input:hover {
  border-color: var(--tags-hover-border-color);
}
.tagify--outside.tagify--focus .tagify__input {
  transition: 0s;
  border-color: var(--tags-focus-border-color);
}

.rotate {
  transform: rotate(180deg);
}

.rc-0 {
  width: 220px;
}