@font-face { font-family: Hind; 
  src: url('./Components/Fonts/Hind-Light.ttf'); } 


.m-100 {
margin: 100px 0;
} 

* {
  box-sizing: border-box;
  font-family: 'Hind';
}