.popup {
  position:fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100%;
  height: 100%;
  background-color: #0e0e2c87;
  z-index: 5 !important;
  overflow: hidden !important;
}


.popup-inner {
  position:absolute;
  transform: translate(50%, -70%);
  width: 448px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  display:flex;
  flex-direction: column;
}

.popup-header {
  margin-bottom: 12px;
  background-color: #009999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.popup-header h1 {
  margin: 0 !important;
  padding: 24px 0 24px 24px !important;
  font-weight: 600;
  font-size: 24px;
  color: white !important;
}

.popup-inner .close-btn {
  background: none;
  border: none;
  color: white;
  font-weight: 800;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 24px;
}

.close-btn:hover {
  background-color: #1aaca9;
  border-radius: 100px;
}

span {
  color: #ac3929;
}

.popup-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-left: 20px;
}

.p-content {
  padding: 12px 0;
  width: 90%;
}

.popup-btn {
  text-align: center;
  margin: 20px 0 48px 0px;
}

.p-content input {
  width: 363px !important;
  height: 28px !important;
  font-size: 16px !important;
  text-decoration: none !important;
  border-bottom: rgba(192, 192, 192, 0.507) solid !important;
}

.p-content input:hover {
  border-bottom: 2px solid #009999 !important;
}

.p-content input:focus {
  outline:none !important;
}

.p-content label {
  padding: 0 0 8px 0 !important;
}

.update-btn {
  width: 151px;
  height: 40px;
  background-color: #009999;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: white;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #009999 !important;
}

.update-btn:hover {
  background-color: #00aaaa;
}
