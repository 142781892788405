.header {
    height: 500px;
    text-align: center;
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8));
    background-position: 50% 50%;
    background-color: light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Droid Sans;
}

.header h1 {
    font-size: 3px;
    color: #000000;
    font-family: "Yatra One", cursive;
}

.header p {
    font-family: "Yatra One", cursive;
    font-size: 1.5px;
}

.timeline {
    padding: 50px;
}

.timeline ul {
    padding: 0;
}


.timeline .default-line {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    background: #bdc3c7;
    height: 900px;
}

.timeline .draw-line {
    width: 4px;
    height: 0;
    position: absolute;
    left: 0;
    background: #F46745;
}

.timeline ul li {
    list-style-type: none;
    position: relative;
    width: 2px;
    margin: 0;
    height: 300px;
    background: transparent;
}

.timeline ul li.in-view {
    transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out;
}

.timeline ul li.in-view::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-image: url("https://sg0duxoli5-flywheel.netdna-ssl.com/wp-content/themes/inspired_elearning_theme/images/check-dark.svg");
    background-color: #F46745;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out;
}

.timeline ul li::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: inherit;
    background: #bdc3c7;
    transition: all 0.4s ease-in-out;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.number {
    position: absolute;
    left: -6px;
    top: -2px;
    font-size: 28px;
    font-weight: bold;
    color: white;
}

.stepDetail {
    position: absolute;
    top: 0;
    left: 70px;
    white-space: nowrap;
}

.number-small {
    height: 50px;
    width: 50px;
    background-color: #F46745;
    border-radius:50%;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:22px;
    font-weight:600;
    color: white;
    margin-bottom: 10px;
}
.small {
    display: none;
}

@media only screen and (max-width: 768px) {
    .timeline {
        display: none;
    }
    .small {
        display: block;
    }
    .pxove-container{
        display: none;
    }
    
}