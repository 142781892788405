* {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.design {
  margin-top: 6px;
}

.login_bigwrapper {
  min-height: 100vh;
  background-color: #F1F3F6 !important;
  position: relative;
}
#root {
  margin: 0;
  padding: 0;
}

.Toastify {
  margin: 0 0;
  padding: 0;
}

.auth-wrapper {
  padding: 0%;
  margin: 0 25%;
}

#login_container {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 468px !important;
  min-width: 632px !important;
  width: 632px;
  height: 468px;
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(0px 4px 20px rgba(6, 30, 70, 0.08));
}

#fir-cont {
  border-radius: 25px 0 0 25px;
  background-color: white;
  padding: 0;
  position: relative;
  width: 416px;
}

#displayOnSm {
  visibility: hidden;
}

.cont-cred {
  padding-top: 52px;
}

.invalidError {
  padding: 8px;
  background-color: #cc4736;
  color: white;
  border-radius: 6px;
  margin: 32px 48px 0px;
}

#f1 {
  height: 100% !important;
}

.svg-cls {
  display: inline-block;
}

#fg-email {
  padding: 0px 48px;

}

#fg-email :focus-within {
  background-color: rgba(241, 243, 246, 1);
}

#fg-email :hover {
  cursor: pointer;
}

.fg-email-div {
  border-bottom: 1px solid #a1b6cf;
}

.fg-email-div:hover {
  cursor: pointer;
  border-bottom: 2px solid #1aaca9;
}

#login-email {
  background-color: transparent !important;
  border: 200px solid;
  height: 2rem;
  font-size: 16px;
}
#login-email:focus {
  border-color: white !important;
  box-shadow: none !important;
}

#fg-pass {
  padding: 0px 48px;

}

#fg-pass :focus-within {
  background-color: rgba(241, 243, 246, 1);
}

#fg-pass :hover {
  cursor: pointer;
}

#login-password {
  background-color: transparent;
  border: 0px solid;
  height: 2rem;
  top: 0;
  left: 13%;
  font-size: 16px;
}

#login-password:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.inline {
  display: inline;
}

#login-button {
  width: 113px;
  height: 48px;
  margin: 16px 0;
  border: 0;
  border-radius: 30px;
  color: white;
  background-color: #009999;
  padding: 12px 24px;
  text-align: center;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  wrap: nowrap;
}

#sec-half {
  background-color: #1aaca9;
  border-radius: 0 25px 25px 0;
  position: relative;
  width: 216px;
}
#sec-half svg {
  position: absolute;
  max-width: 70%;
}
#sec-half svg:nth-child(1) {
  right: 0%;
}
#sv2 {
  bottom: 0%;
}

.s-pas-container {
  text-decoration: none;
}

#s-pas {
  padding: 0 !important;
  font-weight: 600;
  position: static;
  font-family: Hind;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #3a4468;
}

#sec-container {
  display: inline-block;
  color: white;
}

#reg-but {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.new-p-login {
  font-size: 1.8rem;
  position: absolute;
  text-align: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
}

@media only screen and (max-width: 1222px) {
  html {
    font-size: 74%;
  }
}

@media only screen and (max-width: 1065px) {
  html {
    font-size: 64%;
  }
}

@media only screen and (max-width: 923px) {
  html {
    font-size: 54%;
  }
}

@media only screen and (max-width: 767px) {
  #login_container {
    display: flex;
    position: absolute;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-width: 100%;
    filter: drop-shadow(0px 4px 20px rgba(6, 30, 70, 0.08));

  }

  #fir-cont {
    border-radius: 0px;
    height: 100% !important;
    width: 100% !important;
    min-width: 100%;
    min-height: 100%;
  }

  .cont-cred {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 32px !important;
    margin-left: 40px;
    margin-right: 40px;
  }

  #fg-email,
  #fg-pass {
    position: relative !important;
    margin: auto !important;
    width: 100% !important;
    gap: 1rem !important;
  }

  #sec-half {
    visibility: hidden;
    display: none;
  }

  #displayOnSm {
    width: 102%;
    visibility: visible;
    margin-top: 40px;
    position: absolute;
    bottom: 0;
    background-color: #3a4468;
  }

  #displayOnSm .btn {
    font-weight: 500;
    color: white !important;
    padding: 16px 63px 16px 63px;
    font-size: 16px;
    line-height: 24px;
    border: 0;
  }

  #login-button {
    position: static;
    transform: none;
    font-size: 2rem !important;
    font-weight: 500;
    margin-top: 20px;
    width: 113px;
    height: 48px;
    wrap: nowrap;
  }

  #f1 p {
    margin-top: 36px;
    font-size: 2rem;
  }

  #f1 p a {
    text-decoration: none;
  }

  #s-pas {
    padding: 0 !important;
    position: static;
    font-family: Hind;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #3a4468;
  }

  .login_bigwrapper {
    overflow: hidden !important;
  }

  .design {
    margin-top: 16px;
  }

  #login-email,
  #login-password {
    font-size: 16px;
    line-height: 24px;
    padding-left: 12px;
  }
}
