.heading {
    color: #3A4468;
}
.sideDesign {
    position:absolute;
    top: 40%;
    left: 0;
    z-index: -1;
}
@media only screen and (max-width:700px) {
    .sideDesign {
        display: none;
    }
}