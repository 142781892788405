.paylog-container {
  min-height: 100vh;
  background-color: rgba(241, 243, 246, 1);
  position: relative;
}

.svgDesign {
  background-color: rgba(241, 243, 246, 1);
}

#firstSVG {
  margin-top: 24px;
}

.pay-box {
  padding: 48px 0px 2.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 912px;
  height: 452px;
  border-radius: 20px;
  background-color: white;
  
}

.pay-box > h3 {
  position: absolute;
  text-align: center;
  top: -72px;
  transform: translate(120%,0);
}

.pay-box > svg {
  display: none;
}

.payLog-innerBox {
  max-width: 100%;
  height: 356px;

  margin-top: 3px;
  padding: 0px 2px;
  position: relative;
}
.payLog-innerBox::after {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -49%);
  content: "";
  width: 85%;
  height: 1px;
  background-color: #c9cfdb;
}

.first-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.first-box h3 {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 133, 129, 1);
  text-align: center;
  margin-bottom: 32px;
}

.month-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.month-box-cont,
.year-box-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month-box-cont h5 {
  font-weight: 400;
  font-size: 16px;
  color: #3a4468;
  margin: 0 !important;
  margin-top: 6px !important;
}

.month-box-cont:nth-child(2) .month-box {
  border-radius: 0px 10px 10px 0px !important;
}

.month-box-cont:nth-child(1) .month-box {
  border-radius: 10px 0px 0px 10px !important;
}

.month-box {
  width: 250px;
  padding: 12px 0;
  height: 48px;
  background: #ffffff;
  border: 1px solid #989bb0;
  border-radius: 1px;

  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  
  color: #989bb0;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;

  text-transform: capitalize;
}

.month-box:hover {
  background-color:rgba(0, 153, 153, 1) !important;
  color:white !important;
}

.year-box {
  width: 22px;
  text-align: center;
  padding: 0.9px 0;
  background: #eaf6f5;
  border: 1px solid #009999;
  border-radius: 0px 1px 1px 0px;
  font-weight: 600;
  font-size: 2.4px;
  color: #008581;
  cursor: pointer;
}

.code-btn {
  position: absolute;
  bottom: 5%;
}
.code-btn p {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0;
}

.continue-btn-pl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.continue-btn-pl input {
  width: 200px;
  height: 32px;
  color: #989bb0;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
  border-bottom: 1px #c9cfdb solid !important;
  transition: all 0.3s ease;
}
.continue-btn-pl input:hover {
  border-bottom: 2px #009999 solid !important;
}

.continue-btn-pl input::placeholder {
  text-align: center;
  color: #989bb0;
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.continue-btn-pl button {
  width: 145px;
  height: 48px;
  background: #009999;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  font-family: Hind;
  font-size: 16px;
  border: none;
  padding: 12px 24px;
  margin-top: 26px;
  cursor: pointer;
}

.continue-btn-pl svg {
  vertical-align: middle;
}

@media only screen and (max-width:767px) {
  .pay-box {
    min-width: 100%;
    min-height: 100%;
    border-radius: 0;
    padding: 0;
    top: 0;
    left: 0;
    transform:none;
    width: auto;
    height: auto;
    padding: 0 !important;
  }

  .pay-box svg {
    position:static;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .pay-box h3 {
    position: static;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom:24px;
    transform:none;
  }

  .month-cont {
    flex-direction: column-reverse !important;
  }

  .payLog-innerBox::after {
    display: none;
  }

  .month-box {
    border-radius:10px;
  }

  .month-box-cont {
    margin-bottom: 16px;
  }
  
  .month-box-cont h5 {
    margin-top: 8px !important;
  }

  .month-box-cont .month-box {
    border-radius: 0 !important;
  }

  .code-btn {
    position: static;
  }

  .code-btn p {
    margin: 8px 24px 20px 24px;
    text-align: center;
  }

  .continue-btn-pl button svg {
    margin: 0;
  }

  nav {
    display: none;
  }

  .svgDesign {
    display: none;
  }

  .pay-box > svg {
    display:unset;
  }
}