.fp-container{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f8f8f8ad;
}

.fp-container .fp-loader{
  top: 30%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}