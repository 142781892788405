* {
  margin: 0;
  padding: 0;
}

.reg_bigwrapper {
  min-height: 100vh;
  background-color: #F1F3F6 !important;
  position: relative;
}
#root {
  margin: 0;
  padding: 0;
}

.Toastify {
  margin: 0 0;
  padding: 0;
}

.auth-wrapper {
  padding: 0%;
  margin: 0 25%;
}

#reg_container {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 400px !important;
  width: 632px;
  height: 468px;
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(0px 4px 20px rgba(6, 30, 70, 0.08));

}

#fir-cont {
  border-radius: 25px 0 0 25px;
  background-color: white;
  padding: 0;
  position: relative;
  width: 416px;
}

#displayOnSm {
  visibility: hidden;
}

.cont-cred-reg {
  padding-top: 2rem;
}

#f1-reg {
  height: 100% !important;
}

.svg-cls {
  display: inline-block;
}

#fg-email {
  padding: 0px 48px;
}

.fg-email-div {
  border-bottom: 1px solid #a1b6cf;
}
.fg-email-div:hover {
  cursor: pointer;
  border-bottom: 2px solid #1aaca9;
}

#login-email {
  background-color: transparent !important;
  border: 0px solid;
  height: 2rem;
}

#fg-pass {
  padding: 0px 48px;
}

#fg-pass :hover {
  cursor: pointer;
}

#reg-password {
  background-color: transparent;
  border: 0px solid;
  height: 2rem;
  top: 0;
  left: 13%;
}

#reg-password:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.inline {
  display: inline;
}

#reg-button {
  margin-top: 32px;
  border: 0;
  border-radius: 30px;
  color: white;
  background-color: #009999;
  padding: 12px 24px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

#sec-half-reg {
  background-color: #6389b0;
  border-radius: 0 25px 25px 0;
  position: relative;
  width: 216px;
  
}
#sec-half-reg svg {
  position: absolute;
  max-width: 70%;
}
#sec-half-reg svg:nth-child(1) {
  right: 0%;
}

#sv2 {
  bottom: 0%;
}

#sec-container {
  display: inline-block;
  color: white;
}
#sec-container button {
  margin-bottom: 35px;
  color: black;
  background-color: white;
  border: 0;
  padding: 0.7rem 1.12rem;
  border-radius: 50px;
  font-weight: 500 !important;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
}


.new-p {
  font-size: 1.8rem;
  position: absolute;
  text-align: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-weight: 500;

  
}

@media only screen and (max-width: 1222px) {
  html {
    font-size: 74%;
  }
}

@media only screen and (max-width: 1065px) {
  html {
    font-size: 64%;
  }
}

@media only screen and (max-width: 923px) {
  html {
    font-size: 54%;
  }
}

.pageHead {
  text-align: center;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .pageHead {
    padding-top: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0;
  }


  #fir-cont {
    border-radius: 0px;
    height: 100% !important;
    width: 100% !important;
    min-width: 100%;
    min-height: 100%;
  }

  .cont-cred-reg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 24px !important;
  }

  #fg-email,
  #fg-pass {
    position: relative !important;
    margin: auto !important;
    width: 100% !important;
    gap: 1rem !important;
  }

  #sec-half-reg {
    visibility: hidden;
    display: none;


  }
 

  #displayOnSm {
    width: 102%;
    visibility: visible;
    margin-top: 40px;
    position: absolute;
    bottom: 0;
    background-color: #3a4468;
    margin-bottom: 55px;

  }

  #displayOnSm .btn {
    font-weight: 500;
    color: white !important;
    padding: 16px 63px 16px 63px;
    font-size: 16px;
    line-height: 24px;
    border: 0;
  }

  #reg-button {
    position: relative;
    transform: none;
    font-size: 1.8rem !important;
    margin-top: 0px;
    width: 113px;
    height: 48px;
    margin-bottom: 190px;
    margin-top: 20px;
    wrap: nowrap;
  }

  #f1-reg p {
    margin-top: 36px;
    font-size: 2rem;
  }

  #f1-reg p a {
    text-decoration: none;
  }

  .login_bigwrapper {
    overflow: hidden !important;
  }
  #reg_container {
    display: flex;
    position: absolute;
    /* top: 100%;
    left: 100%; */
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-width: 100%;
    /* max-height: 468px; */
    /* max-width: 100%;
    max-height: 100%; */
    filter: drop-shadow(0px 4px 20px rgba(6, 30, 70, 0.08));
  
  }
}
